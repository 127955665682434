import React from "react";
import { css } from "styled-components";
import imageUrlBuilder from "@sanity/image-url";
import { buildFileUrl } from "@sanity/asset-utils";
import { createClient, groq } from "next-sanity";
import { config } from "../client";
import BlockContent from "@sanity/block-content-to-react";

/**
 * Initialize Sanity client and image URL builder.
 */
const sanityClient = createClient(config);
const builder = imageUrlBuilder(sanityClient);

/**
 * Generates a file URL for Sanity documents.
 *
 * @param {Object} document - The Sanity document containing the asset reference.
 * @returns {string} - The constructed file URL.
 */
function sanityFileUrl(document) {
  const assetRef = document?.document?.asset?._ref;
  if (!assetRef) {
    console.warn("Asset reference is missing in the document.");
    return "";
  }

  const assetId = assetRef.slice(5, -4); // Extract asset ID from reference

  try {
    const url = buildFileUrl({
      ...config,
      assetId,
      type: "file",
      vanityFilename: null,
      extension: "pdf",
    });
    return url;
  } catch (error) {
    console.error("Error building file URL:", error);
    return "";
  }
}

/**
 * Generates a video URL for Sanity documents.
 *
 * @param {Object} document - The Sanity document containing the video file reference.
 * @returns {string} - The constructed video URL.
 */
function sanityVideoUrl(document) {
  const assetRef = document?.videoFile?.asset?._ref;
  if (!assetRef) {
    console.warn("Video asset reference is missing in the document.");
    return "";
  }

  const assetId = assetRef.slice(5, -4); // Extract asset ID from reference

  try {
    const url = buildFileUrl({
      ...config,
      assetId,
      type: "file",
      vanityFilename: null,
      extension: "mp4",
    });
    return url;
  } catch (error) {
    console.error("Error building video URL:", error);
    return "";
  }
}

/**
 * Generates an image source URL using Sanity's image builder.
 *
 * @param {Object} source - The image source object from Sanity.
 * @returns {string} - The constructed image URL or an empty string if source is invalid.
 */
function sanitySrcUrl(source) {
  if (!source) {
    return "";
  }

  try {
    const src = builder.image(source).url();
    return src || "";
  } catch (error) {
    console.error("Error building image URL:", error);
    return "";
  }
}

/**
 * Composes multiple functions into a single function.
 *
 * @param  {...Function} fns - The functions to compose.
 * @returns {Function} - A function that invokes all provided functions with the given arguments.
 */
const callAll =
  (...fns) =>
  (...args) => {
    fns.forEach((fn) => {
      if (typeof fn === "function") {
        fn(...args);
      }
    });
  };

/**
 * Smoothly scrolls the window to the specified reference.
 *
 * @param {React.RefObject} ref - The React ref to scroll to.
 */
const scrollToRef = (ref) => {
  if (ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop - 100,
      left: 0, // Changed from 100 to 0 for typical horizontal scrolling
      behavior: "smooth",
    });
  }
};

/**
 * Constructs a Mapbox Directions API URL based on trip coordinates.
 *
 * @param {Array<Object>} trip - An array of trip points with longitude and latitude.
 * @returns {string} - The constructed Mapbox Directions API URL.
 */
const mapBoxDirectionsURL = (trip) => {
  if (!trip || !Array.isArray(trip) || trip.length === 0) {
    console.warn("Invalid trip data provided.");
    return "";
  }

  const tripCoordinates = trip
    .map((point) => `${point.longitude},${point.latitude}`)
    .join(";");

  const TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
  if (!TOKEN) {
    console.error("Mapbox token is missing.");
    return "";
  }

  const profile = "driving";
  const origin = "116.139958,-8.867958";
  const coordinates = `${origin};${tripCoordinates};${origin}`;

  const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${TOKEN}`;

  return url;
};

/**
 * Generates a GROQ query to fetch a room by slug.
 *
 * @param {string} slug - The slug of the room.
 * @returns {string} - The GROQ query string.
 */
function groqSanitySlugQuery(slug) {
  const query = groq`*[_type == 'room' && slug.current == "${slug}"]{
    ...,
    'otherRooms': *[_type == 'room' && slug.current != "${slug}" && slug.current != 'villa']{...},
    'PageContactSection': *[_type == "section" && content[0]._type == "contact"]{...}
  }`;

  return query;
}

/**
 * Generates a GROQ query to fetch a page by slug.
 *
 * @param {string} page - The slug of the page.
 * @returns {string} - The GROQ query string.
 */
function groqSanityPageQuery(page) {
  const additionalFields =
    page === "explore-lombok" || page === "what-to-do-in-lombok"
      ? `'markers': *[_type == "exploreLombok"]{...}`
      : "";

  const query = groq`*[_type == 'page' && slug.current == "${page}"]{
    ...,
    'modules': modules[]{
      ...,
      _type == 'reference' => @->,
      _type != 'reference' => @,
      _type == 'roomsCarousel' => { array[]{..., room->} },
      _type == 'sectionCarousel' => { room-> }
    },
    ${additionalFields}
  }`;

  return query;
}

/**
 * Processes Sanity data to extract props for components.
 *
 * @param {Object|Array<Object>} data - The data fetched from Sanity.
 * @returns {Object} - The processed props including data, SEO, and markers.
 */
function propsSanity(data) {
  const documents = Array.isArray(data) ? data : [data];
  const firstDocument = documents[0] || {};
  const markers = firstDocument.markers;

  const combinedModules = documents.flatMap((doc) => doc.modules || []);

  const props = combinedModules.reduce((acc, module) => {
    let key = "";

    switch (module._type) {
      case "section":
        key = module.name.replace(/\s/g, "");
        break;
      case "horizontalScroll":
        key = module.title.replace(/\s/g, "");
        break;
      case "headerTwoColumText":
        key = module.name;
        break;
      case "carousel":
        key = module.name;
        break;
      case "sectionCarousel":
        key = module.header.replace(/\s/g, "");
        break;
      case "documents":
        key = module.document.description.replace(/\s/g, "");
        break;
      default:
        key = module._type;
    }

    return { ...acc, [key]: module };
  }, {});

  return {
    data: props,
    seo: firstDocument.seo || { metaTitle: "", metaDesc: "" },
    ...(markers && { markers }),
  };
}

/**
 * Generates a GROQ query for the "Explore Lombok" page by slug.
 *
 * @param {string} slug - The category slug for markers.
 * @returns {string} - The GROQ query string.
 */
function groqSanityExploreLombokSlugQuery(slug) {
  /**
   * Capitalizes the first letter of a string.
   *
   * @param {string} string - The string to capitalize.
   * @returns {string} - The capitalized string.
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const capitalizedSlug = capitalizeFirstLetter(slug);

  const query = groq`*[_type == 'page' && slug.current == "explore-lombok"]{
    ...,
    'modules': modules[]{
      ...,
      _type == 'reference' => @->,
      _type != 'reference' => @,
      _type == 'roomsCarousel' => { array[]{..., room->} },
      _type == 'sectionCarousel' => { room-> }
    },
    'markers': *[_type == "exploreLombok" && category == "${capitalizedSlug}"]{...},
    'PageContactSection': *[_type == "section" && content[0]._type == "contact"]{...}
  }`;

  return query;
}

/**
 * Sends a Google Analytics event using gtag.
 *
 * @param {string} eventName - The name of the event.
 * @param {Object} params - The parameters for the event.
 */
function sendGATrackingEvent(eventName, params) {
  if (typeof gtag !== "function") {
    console.warn("gtag is not defined.");
    return;
  }

  gtag("event", eventName, params);
}

/**
 * Handles booking link click event.
 */
function handleBookingLinkClick() {
  sendGATrackingEvent("booking_link_click_link_tree", {
    event_category: "Navigation",
    event_label: "Direct Booking Site Link Tree",
  });
}

/**
 * Handles WhatsApp link click event.
 */
function handleWhatsAppLinkClick() {
  sendGATrackingEvent("whatsapp_link_click_link_tree", {
    event_category: "Navigation",
    event_label: "WhatsApp Link Tree",
  });
}

/**
 * Handles website link click event.
 */
function handleWebsiteLinkClick() {
  sendGATrackingEvent("website_link_click_link_tree", {
    event_category: "Navigation",
    event_label: "Website Click Link Tree",
  });
}

/**
 * Handles maps link click event.
 */
function handleMapsLinkClick() {
  sendGATrackingEvent("maps_link_click_link_tree", {
    event_category: "Navigation",
    event_label: "Maps Click Link Tree",
  });
}

/**
 * Handles WhatsApp page click event.
 */
function handleWhatsAppPageClick() {
  sendGATrackingEvent("whatsapp_link_click_page", {
    event_category: "Navigation",
    event_label: "WhatsApp Link Page",
  });
}

/**
 * Creates event options for various user interactions.
 *
 * @param {string} slug - The current page slug.
 * @returns {Object} - An object containing event configurations.
 */
function createEventOptions(slug) {
  return {
    whatsapp_click: {
      event: "whatsapp_click",
      category: "Contact Methods",
      label: "Homepage Icon",
      page: slug,
    },
    footer_email_click: {
      event: "footer_email_click",
      category: "Contact Methods",
      label: "Footer Email",
      page: slug,
    },
    footer_whatsapp_click: {
      event: "footer_whatsapp_click",
      category: "Contact Methods",
      label: "Footer WhatsApp",
      page: slug,
    },
    footer_map_click: {
      event: "footer_map_click",
      category: "Contact Methods",
      label: "Footer Google Maps",
      page: slug,
    },
    contact_form_submit: {
      event: "contact_form_submit",
      category: "Contact Methods",
      label: "Contact Page Form",
      page: slug,
    },
    booking_click_header: {
      event: "booking_click_header",
      category: "Booking Engine",
      label: "Header",
      page: slug,
    },
    booking_click_banner: {
      event: "booking_click_banner",
      category: "Booking Engine",
      label: "Banner",
      page: slug,
    },
    menu_download: {
      event: "menu_download",
      category: "User Engagement",
      label: "Menu PDF",
      page: slug,
    },
    linktree_whatsapp_click: {
      event: "linktree_whatsapp_click",
      category: "Linktree",
      label: "WhatsApp Link",
      page: slug,
    },
    linktree_website_click: {
      event: "linktree_website_click",
      category: "Linktree",
      label: "Website Link",
      page: slug,
    },
    linktree_booking_click: {
      event: "linktree_booking_click",
      category: "Linktree",
      label: "Booking Link",
      page: slug,
    },
    linktree_maps_click: {
      event: "linktree_maps_click",
      category: "Linktree",
      label: "Maps Link",
      page: slug,
    },
    marketing_booking_click: {
      event: "marketing_booking_click",
      category: "Marketing",
      label: "Marketing",
      page: slug,
    },
  };
}

export {
  callAll,
  scrollToRef,
  sanitySrcUrl,
  sanityFileUrl,
  propsSanity,
  groqSanityPageQuery,
  groqSanitySlugQuery,
  groqSanityExploreLombokSlugQuery,
  sanityVideoUrl,
  handleBookingLinkClick,
  handleWhatsAppLinkClick,
  handleWebsiteLinkClick,
  handleMapsLinkClick,
  handleWhatsAppPageClick,
  createEventOptions,
};

import {
  theme,
  breakpoints,
  typo,
  styled,
  motion,
} from "../../styles/base/_allBase.styles";

export const Cursor = styled(motion.div)`
  visible: ${(props) => (props.visible === true ? "true" : "false")};
  position: fixed;
  left: ${(props) => (props.cursorsize === "small" ? "1.25rem" : "0rem")};
  top: ${(props) => (props.cursorsize === "small" ? "1.25rem" : "0rem")};
  width: ${(props) => (props.cursorsize === "small" ? "2.5rem" : "5rem")};
  height: ${(props) => (props.cursorsize === "small" ? "2.5rem" : "5rem")};
  border-radius: 8rem;
  background-color: ${(props) => props.theme.colors.warm.secondary};
  mix-blend-mode: difference;
  z-index: 900;
  pointer-events: none;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Container = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: none;
  border: 1rem solid red;
`;

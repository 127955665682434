import {
  groq,
  createClient,
  createImageUrlBuilder,
  createPortableTextComponent,
  // createPreviewSubscriptionHook,
  createCurrentUserHook,
} from "next-sanity";

export const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || "production",
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || "0ufeozpc",
  token: process.env.NEXT_PUPLIC_SANITY_API_TOKEN,
  useCdn: true,
  apiVersion: "2022-01-01",
};

export const urlFor = (source) => createImageUrlBuilder(config).image(source);

// export const usePreviewSubscription = createPreviewSubscriptionHook(config);
// export const PortableText = createPortableTextComponent({
// 	...config,
// 	serializers: {},
// });

export const sanityClient = createClient(config);

export const previewClient = createClient({
  ...config,
  useCdn: true,
  token: process.env.SANITY_API_TOKEN,
});

export const getClient = (usePreview) =>
  usePreview ? previewClient : sanityClient;
